import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	drawerOpen: {
		marginLeft: theme.spacing(30),
		marginTop: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		[theme.breakpoints.down('xs')]: {
			marginLeft: theme.spacing(0)
		}
	},
	drawerClose: {
		marginLeft: theme.spacing(7),
		marginTop: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		[theme.breakpoints.down('xs')]: {
			marginLeft: theme.spacing(0)
		}
	}
}))

export default function HomeContent(props) {
	const classes = useStyles()

	return (
		<div
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: props.drawerIsOpen,
				[classes.drawerClose]: !props.drawerIsOpen
			})}>
			{props.children}
		</div>
	)
}

HomeContent.propTypes = {
	children: PropTypes.element.isRequired,
	drawerIsOpen: PropTypes.bool.isRequired
}
