import React, { useState } from 'react'

import CardWithContent from '../../../.global/cardWithContent'
import LoansCreateForm from './loanCreate/loansCreateForm'
import LoansTable from './loansTable'
import ScreenOverlay from '../../../.global/screenOverlay'
import Toast from '../../../.global/toast'

export default function LoansContainer() {
	const [employeeList, setEmployeeList] = useState([])
	const [employeeCodeList, setEmployeeCodeList] = useState([])
	const [filterTableCode, setFilterTableCode] = useState('')
	const [loadTableData, setLoadTableData] = useState(false)
	const [isSending, setIsSending] = useState(false)
	const [toast, openToast] = useState(false)
	const [toastLabel, setToastLabel] = useState('')
	const [toastSeverity, setToastSeverity] = useState('success')

	return (
		<div>
			<CardWithContent title={'Create Loan'}>
				<LoansCreateForm
					employeeList={employeeList}
					employeeCodeList={employeeCodeList}
					setFilterTableCode={setFilterTableCode}
					openToast={openToast}
					setIsSending={setIsSending}
					setToastLabel={setToastLabel}
					setToastSeverity={setToastSeverity}
					setLoadTableData={setLoadTableData}
				/>
			</CardWithContent>
			<CardWithContent title={'Outstanding loans'}>
				<LoansTable
					loadTableData={loadTableData}
					setLoadTableData={setLoadTableData}
					employeeList={employeeList}
					setEmployeeList={setEmployeeList}
					setEmployeeCodeList={setEmployeeCodeList}
					filterTableCode={filterTableCode}
				/>
			</CardWithContent>
			<ScreenOverlay isOpen={isSending} />
			<Toast label={toastLabel} severity={toastSeverity} isOpen={toast} setOpen={openToast} />
		</div>
	)
}
