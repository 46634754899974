import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { paths } from '../../../../pages/routes'
import { StyledTable, StyledTableLoading, StyledTableNoData } from '../../../.global/styledTable'
import getAttendanceHistory from '../../../../adapters/attendanceHistory'
import getEmployees from '../../../../adapters/employeeAdapter'

export default function HistoryTable(props) {
	const pageHistory = useHistory()
	const [employees, setEmployees] = useState([])
	const [allHistory, setAllHistory] = useState([])
	const [displayRows, setDisplayRows] = useState([])

	const columns = [{ name: 'Date' }, { name: 'Employee code' }, { name: 'am in' }, { name: 'am out' }, { name: 'pm in' }, { name: 'pm out' }]

	useEffect(() => {
		props.setLoading(true)

		fetchEmployeeAndHistoryData(props.date, props.date)
			.then(({ employees, history }) => {
				setEmployees(employees)

				if (history) {
					setAllHistory(history)
					setDisplayRows(sortDataForDisplay(employees, history, props.employeeFilter, props.displayGaps))
				}

				props.setLoading(false)
			})
			.catch((error) => {
				if (error.message === 'Invalid session') {
					pageHistory.push(paths.signin)
				}

				setAllHistory([])
				setDisplayRows([])
				props.setLoading(false)
			})
	}, [props.date])

	useEffect(() => {
		setDisplayRows(sortDataForDisplay(employees, allHistory, props.employeeFilter, props.displayGaps))
	}, [props.employeeFilter, props.displayGaps])

	if (displayRows.length === 0 && !props.loading) {
		return <StyledTableNoData columns={columns} />
	}

	if (props.loading) {
		return <StyledTableLoading columns={columns} />
	}

	return <StyledTable columns={columns} rows={displayRows} />
}

export async function fetchEmployeeAndHistoryData(startDate, endDate) {
	const [employeeResponse, historyResponse] = await Promise.all([getEmployees(), getAttendanceHistory(startDate, endDate)])

	if (employeeResponse.status == 401 || employeeResponse.status == 403 || historyResponse.status == 401 || historyResponse.status == 403) {
		throw new Error('Invalid session')
	} else if (!employeeResponse.ok) {
		throw new Error(`An error has occured: ${employeeResponse.status}`)
	} else if (!historyResponse.ok) {
		throw new Error(`An error has occured: ${historyResponse.status}`)
	}

	const employees = await employeeResponse.json()
	const history = await historyResponse.json()

	if (history) return { employees, history }
	else return { employees }
}

function sortDataForDisplay(employees, history, employeeFilter, displayGaps) {
	if (history) {
		var mergedData = history.map((loan) => ({ ...loan, ...employees.find((employee) => employee.employeeCode === loan.employeeCode) }))

		if (displayGaps) mergedData = mergedData.filter((row) => containsGap(row.amLogIn, row.amLogOut, row.pmLogIn, row.pmLogOut))
		if (employeeFilter) mergedData = mergedData.filter((row) => row.fullName.toLowerCase().includes(employeeFilter.toLowerCase()))

		return mergedData.map((row) => ({
			date: row.date,
			fullName: row.fullName,
			amLogIn: row.amLogIn,
			amLogOut: row.amLogOut,
			pmLogIn: row.pmLogIn,
			pmLogOut: row.pmLogOut
		}))
	}
}

function containsGap(amLogIn, amLogOut, pmLogIn, pmLogOut) {
	if (!amLogIn || !amLogOut || !pmLogIn || !pmLogOut) return true
	else return false
}

HistoryTable.propTypes = {
	loading: PropTypes.bool.isRequired,
	setLoading: PropTypes.func.isRequired,
	date: PropTypes.object.isRequired,
	employeeFilter: PropTypes.string,
	displayGaps: PropTypes.bool.isRequired
}
