import format from 'date-fns/format'
import { getHeaders } from './adapterHelper'

export default function completeSummary(data, startDate, endDate) {
	const dateFormat = 'yyyy-MM-dd'
	const startDateFormated = format(startDate, dateFormat)
	const endDateFormated = format(endDate, dateFormat)

	var myHeaders = getHeaders()

	var myBody = new URLSearchParams()
	myBody.append('startDate', startDateFormated)
	myBody.append('endDate', endDateFormated)
	myBody.append('details', JSON.stringify(data))
	myBody.append('isConfirmed', true)

	var request = {
		method: 'POST',
		headers: myHeaders,
		body: myBody
	}

	return fetch('/api/import/payroll', request)
}
