import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './pages/routes'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import { myTheme } from './styles/theme'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

LogRocket.init('malafs/sweldo')
setupLogRocketReact(LogRocket)

class App extends React.Component {
	render() {
		return (
			<ThemeProvider theme={createMuiTheme(myTheme)}>
				<Routes />
			</ThemeProvider>
		)
	}
}

ReactDOM.render(<App />, document.getElementById('root'))
