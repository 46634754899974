import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import React from 'react'

import HomeContainer from './home/helpers/homeContainer'
import NotFound from './notFound'
import Signin from './signin'

export const paths = {
	signin: '/signin',
	homeContainer: '/home',
	attendanceTracker: '/home/attendance/tracker',
	attendanceHistory: '/home/attendance/history',
	payrollSummary: '/home/payroll/summary',
	payrollLoans: '/home/payroll/loans',
	payrollEmployees: '/home/payroll/employees'
}

export default function Routes() {
	return (
		<BrowserRouter basename="/">
			<Switch>
				<Route exact path="/">
					<Redirect to={paths.signin} />
				</Route>
				<Route exact path={paths.signin}>
					<Signin />
				</Route>
				<Route path={paths.homeContainer}>
					<HomeContainer />
				</Route>
				<Route>
					<NotFound fromHome={false} />
				</Route>
			</Switch>
		</BrowserRouter>
	)
}
