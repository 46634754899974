import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import React, { useState } from 'react'

import CardWithContent from '../../../.global/cardWithContent'
import Hovertip from '../../../.global/hovertip'
import Modal from '../../../.global/modal'
import ScreenOverlay from '../../../.global/screenOverlay'
import Toast from '../../../.global/toast'
import TrackerForm from './trackerForm'
import TrackerHistoryTable from '../components/trackerHistoryTable'

const howToLogAttendance1 = 'Time selection is determined automatically depending on the time of day. This can be manually changed temporarily by clicking on one of the provided options.'
const howToLogAttendance2 = 'Manually enter an employee code and click the record button.'
const howToLogAttendance3 = 'This information will be saved to track how many hours employees are working.'

const useStyles = makeStyles((theme) => ({
	helpIcon: {
		color: theme.palette.primary.main,
		fontSize: '30px'
	},
	height: {
		height: '41px'
	}
}))

export default function TrackerContainer() {
	const [isSending, setIsSending] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [toast, openToast] = useState(false)
	const [toastLabel, setToastLabel] = useState('')
	const [toastSeverity, setToastSeverity] = useState('success')
	const [attendanceHistory, setAttendanceHistory] = useState([])

	const classes = useStyles()

	return (
		<div>
			<CardWithContent title={'Attendance tracker'} actionButtons={renderActionButtonc()}>
				<TrackerForm
					isSending={isSending}
					setIsSending={setIsSending}
					openToast={openToast}
					setToastOpen={openToast}
					setToastLabel={setToastLabel}
					setToastSeverity={setToastSeverity}
					attendanceHistory={attendanceHistory}
					setAttendanceHistory={setAttendanceHistory}
				/>
			</CardWithContent>
			<CardWithContent title={'Session log'} minWidth={'800px'}>
				<TrackerHistoryTable rows={attendanceHistory} />
			</CardWithContent>
			<ScreenOverlay isOpen={isSending} />
			<Toast label={toastLabel} severity={toastSeverity} isOpen={toast} setOpen={openToast} />
			<Modal
				modalIsOpen={modalIsOpen}
				setModalState={setModalIsOpen}
				title="How to record attendance"
				buttonText="Close"
				message1={howToLogAttendance1}
				message2={howToLogAttendance2}
				message3={howToLogAttendance3}
			/>
		</div>
	)

	function renderActionButtonc() {
		return (
			<Hovertip label="How to record attendance" placement="left">
				<IconButton
					className={classes.height}
					id="record"
					type="submit"
					variant="contained"
					color="primary"
					hidden={true}
					onClick={() => {
						setModalIsOpen(true)
					}}>
					<InfoOutlinedIcon className={classes.helpIcon} />
				</IconButton>
			</Hovertip>
		)
	}
}
