import { isThursday, isThisWeek } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import AnimateHeight from 'react-animate-height'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import { paths } from '../../../../../pages/routes'
import { postCreateLoan, doesEmployeeExist, isSubmitable, setLoanTypeWithDefaults, calculatePaymentDetails, getEmployeeName, isLoanAmountAcceptable } from './loanCreateLogic'
import DateSelect from '../../../../.global/dateSelect'
import TextFieldCurrency from '../../../../.global/textFieldCurrency'

const loanTypes = {
	cashAdvance: { name: 'Cash advance', value: 'cashAdvance' },
	sss: { name: 'SSS loan', value: 'sss' },
	hdmf: { name: 'HDMF loan', value: 'hdmf' }
}

const useStyles = makeStyles((theme) => ({
	formField: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	flexDiv: {
		display: 'flex'
	},
	centreDiv: {
		textAlign: 'center',
		marginBottom: theme.spacing(1)
	},
	marginLeft: {
		marginLeft: theme.spacing(1)
	},
	marginRight: {
		marginRight: theme.spacing(1)
	},
	dateSelect: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	}
}))

export default function LoansCreateForm(props) {
	const classes = useStyles()
	const [height, setHeight] = useState(120)
	const [loanSubmitable, setLoanSubmitable] = useState(false)
	const [employee, setEmployee] = useState({
		code: '',
		hasError: false,
		helperText: ''
	})
	const [employeeName, setEmployeeName] = useState('')
	const [loanDetails, setLoanDetails] = useState({
		type: '',
		amount: '',
		hasError: false,
		helperText: '',
		textFieldLabel: 'Loan amount'
	})
	const [payAtNextPayDay, setPayAtNextPayDay] = useState(false)
	const [dueDate, setDueDate] = useState(new Date())
	const [weeksUntilPaid, setWeeksUntilPaid] = useState(0)
	const [weeklyPayments, setWeeklyPayments] = useState(0)

	useEffect(() => {
		setHeight(document.getElementById('createLoanContainer').clientHeight)
		const canSubmit = isSubmitable(loanDetails, props.employeeCodeList, employee, loanTypes)
		setLoanSubmitable(canSubmit)
		calculatePaymentDetails(dueDate, loanDetails.amount, payAtNextPayDay, setWeeksUntilPaid, setWeeklyPayments)
		setEmployeeName(getEmployeeName(props.employeeList, props.employeeCodeList, employee))
	})

	function handleCreateLoan() {
		props.setIsSending(true)

		postCreateLoan(employee.code, loanDetails.type, loanDetails.amount, dueDate, payAtNextPayDay, weeklyPayments, employee, setEmployee, setLoanDetails)
			.then(() => {
				prepareToast('Loan created', 'success')
				props.setLoadTableData(true)
			})
			.catch((error) => {
				if (error.message === 'Invalid session') {
					history.push(paths.signin)
				} else {
					prepareToast(error.message, 'error')
				}
			})

		props.setIsSending(false)
	}

	function prepareToast(label, severity) {
		props.setToastLabel(label)
		props.setToastSeverity(severity)
		props.openToast(true)
	}

	return (
		<AnimateHeight duration={300} height={height}>
			<div id={'createLoanContainer'}>
				{displayEmployeeCodeAndName()}
				{displayLoanTypeAndAmount()}
			</div>
		</AnimateHeight>
	)

	// ••••••••••••••••••••••••••••••••••••
	//          Dynamic Displays
	// ••••••••••••••••••••••••••••••••••••
	function displayEmployeeCodeAndName() {
		if (doesEmployeeExist(props.employeeCodeList, employee.code)) {
			props.setFilterTableCode(employee.code)
			return (
				<div className={classes.flexDiv}>
					{renderEmployeeCode()}
					{renderEmployeeName()}
				</div>
			)
		} else {
			props.setFilterTableCode('')
			return renderEmployeeCode()
		}
	}

	function displayLoanTypeAndAmount() {
		if (doesEmployeeExist(props.employeeCodeList, employee.code)) {
			if (loanDetails.type) {
				return (
					<div>
						<div className={classes.flexDiv}>
							{renderLoanType()}
							{renderLoanAmount()}
						</div>
						{displayLoanRepaymentDetails()}
						{renderCreateLoanButton()}
					</div>
				)
			} else {
				return (
					<div>
						<div className={classes.flexDiv}>{renderLoanType()}</div>
						{displayLoanRepaymentDetails()}
						{renderCreateLoanButton()}
					</div>
				)
			}
		}
	}

	function displayLoanRepaymentDetails() {
		const { type, amount } = loanDetails
		const { cashAdvance, sss, hdmf } = loanTypes

		if (type && amount) {
			if (type === sss.value || type === hdmf.value) {
				return displayIncrementalDetails()
			} else if (type === cashAdvance.value) {
				return (
					<div>
						<div className={classes.centreDiv}>{renderPayLoanAtNextPayDay()}</div>
						{displayIncrementalDetails()}
					</div>
				)
			}
		}
	}

	function displayIncrementalDetails() {
		if (!payAtNextPayDay) {
			return (
				<div>
					<div className={classes.flexDiv}>
						{renderDueDate()}
						{renderNumberOfPayments()}
						{renderPaymentAmounts()}
					</div>
				</div>
			)
		}
	}

	// ••••••••••••••••••••••••••••••••••••
	//          Field renders
	// ••••••••••••••••••••••••••••••••••••
	function renderEmployeeCode() {
		return (
			<TextField
				className={`${classes.formField} ${classes.marginRight}`}
				onChange={(e) => {
					setEmployee({
						...employee,
						code: e.target.value
					})
				}}
				value={employee.code}
				error={employee.hasError}
				helperText={employee.helperText}
				inputProps={{ maxLength: 10 }}
				label="Employee code"
				variant="outlined"
				autoFocus
			/>
		)
	}

	function renderEmployeeName() {
		return <TextField className={`${classes.formField} ${classes.marginLeft}`} value={employeeName} label="Employee name" variant="outlined" disabled />
	}

	function renderCreateLoanButton() {
		if (loanSubmitable) {
			return (
				<Button className={classes.formField} variant="contained" color="primary" onClick={handleCreateLoan}>
					Submit loan
				</Button>
			)
		} else {
			return (
				<Button className={classes.formField} variant="contained" color="primary" disabled>
					Submit loan
				</Button>
			)
		}
	}

	function renderLoanType() {
		const { cashAdvance, sss, hdmf } = loanTypes

		return (
			<FormControl variant="outlined" className={`${classes.formField} ${classes.marginRight}`}>
				<InputLabel>Loan Type</InputLabel>
				<Select
					native
					onChange={(e) => {
						setLoanTypeWithDefaults(e, setPayAtNextPayDay, loanDetails, setLoanDetails, setDueDate, loanTypes, employee, setEmployee)
					}}
					value={loanDetails.type}
					label="Loan type"
					inputProps={{
						name: 'loanType',
						id: 'loanTypeDropDown'
					}}>
					<option value="" defaultValue disabled hidden />
					<option value={cashAdvance.value}>{cashAdvance.name}</option>
					<option value={sss.value}>{sss.name}</option>
					<option value={hdmf.value}>{hdmf.name}</option>
				</Select>
			</FormControl>
		)
	}

	function changeLoanAmount(event) {
		setLoanDetails({ ...loanDetails, amount: event.target.value, hasError: !isLoanAmountAcceptable(loanDetails, loanTypes, event.target.value) })
	}

	function renderLoanAmount() {
		return (
			<TextFieldCurrency
				classes={`${classes.formField} ${classes.marginLeft}`}
				onChange={(e) => {
					changeLoanAmount(e)
				}}
				value={loanDetails.amount}
				label={loanDetails.textFieldLabel}
				error={loanDetails.hasError}
				helperText={loanDetails.helperText}
			/>
		)
	}

	function renderPayLoanAtNextPayDay() {
		return <FormControlLabel control={<Checkbox checked={payAtNextPayDay} onChange={(e) => setPayAtNextPayDay(e.target.checked)} color="primary" />} label="Pay loan in full next pay day" />
	}

	function renderDueDate() {
		return (
			<DateSelect
				label={'Due date'}
				value={dueDate}
				onChange={setDueDate}
				disablePast={true}
				shouldDisableDate={(date) => {
					if (!isThursday(date) || isThisWeek(date)) return true
					else return false
				}}
				optionalClass={classes.dateSelect}
			/>
		)
	}

	function renderNumberOfPayments() {
		return <TextField className={`${classes.formField} ${classes.marginRight} ${classes.marginLeft}`} variant="outlined" value={weeksUntilPaid} label="Weeks until due date" disabled />
	}

	function renderPaymentAmounts() {
		return <TextField className={`${classes.formField} ${classes.marginLeft}`} variant="outlined" value={weeklyPayments} label="Weekly payments" disabled />
	}
}

LoansCreateForm.propTypes = {
	employeeList: PropTypes.array.isRequired,
	employeeCodeList: PropTypes.array.isRequired,
	setFilterTableCode: PropTypes.func.isRequired,
	setIsSending: PropTypes.func.isRequired,
	openToast: PropTypes.func.isRequired,
	setToastLabel: PropTypes.func.isRequired,
	setToastSeverity: PropTypes.func.isRequired,
	setLoadTableData: PropTypes.func.isRequired
}
