import { getLoans } from '../../../../adapters/loanAdapter'
import getEmployees from '../../../../adapters/employeeAdapter'

export async function fetchEmployeeAndLoanData() {
	const [employeeResponse, loanResponse] = await Promise.all([getEmployees(), getLoans()])

	if (employeeResponse.status == 401 || employeeResponse.status == 403 || loanResponse.status == 401 || loanResponse.status == 403) {
		throw new Error('Invalid session')
	} else if (!employeeResponse.ok) {
		throw new Error(`An error has occured: ${employeeResponse.status}`)
	} else if (!loanResponse.ok) {
		throw new Error(`An error has occured: ${loanResponse.status}`)
	}

	const employees = await employeeResponse.json().catch(() => {
		return
	})
	const loans = await loanResponse.json().catch(() => {
		return
	})

	if (loans) return { employees, loans }
	else return { employees }
}

export function sortDataForDisplay(employees, loans, filterTableCode) {
	if (loans) {
		var mergedData = loans.map((loan) => ({ ...loan, ...employees.find((employee) => employee.employeeCode === loan.employeeCode) }))

		if (filterTableCode) mergedData = mergedData.filter((row) => row.employeeCode == filterTableCode)

		return mergedData.map((row) => ({
			id: row.employeeNumber,
			fullName: row.fullName,
			loanType: loanTypeString(row.type),
			loamAmount: row.totalLoan.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
			amountDue: row.remainingBalance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
			loanDate: row.loanDate,
			dueDate: row.dueDate
		}))
	}
}

function loanTypeString(type) {
	if (type == 'cashadvance') return 'Cash advance'
	if (type == 'sss') return 'SSS loan'
	if (type == 'hdmf') return 'HDMF loan'

	return 'Unknown'
}
