import PropTypes from 'prop-types'
import React from 'react'

import { StyledTable, StyledTableNoData } from '../../../.global/styledTable'

export default function TrackerHistoryTable(props) {
	const columns = [{ name: 'ID' }, { name: 'Full name' }, { name: 'Type' }, { name: 'Time' }, { name: 'Status' }]

	if (props.rows.length === 0) {
		return <StyledTableNoData columns={columns} />
	}

	return <StyledTable columns={columns} rows={props.rows} />
}

TrackerHistoryTable.propTypes = {
	rows: PropTypes.array.isRequired
}
