import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { paths } from '../../../../pages/routes'
import { StyledTable, StyledTableLoading, StyledTableNoData } from '../../../.global/styledTable'
import getPayrollSummary from '../../../../adapters/paryollSummaryAdapter'

export default function SummaryTable(props) {
	const [rows, setRows] = useState([])
	const history = useHistory()

	const columns = [
		{ name: 'ID' },
		{ name: 'Full name' },
		{ name: 'Hours worked' },
		{ name: 'Base pay' },
		{ name: 'Contribution' },
		{ name: 'Loan(s)' },
		{ name: 'Holiday Pay' },
		{ name: 'Net Receivable' }
	]

	useEffect(() => {
		props.setLoading(true)

		getPayrollSummary(props.startDate, props.endDate)
			.then((response) => {
				if (response.status == 200) {
					response.json().then((payrollData) => {
						const displayRows = payrollData.details.map((payroll) => ({
							id: payroll.id,
							fullName: payroll.fullName,
							hoursWorked: payroll.totalHoursWorked.toLocaleString(),
							basePay: payroll.basePay.toLocaleString(),
							contribution: payroll.contribution.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							loans: payroll.totalLoansPayable.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							holidayPay: payroll.totalHolidayPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							netReceivable: payroll.netReceivable.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}))

						const bankSummaryData = payrollData.details.map((payroll) => ({
							name: payroll.fullName,
							account: payroll.bankAccountShorthand,
							amount: payroll.netReceivable.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}))

						const payrollSummaryData = payrollData.details.map((payroll) => ({
							id: payroll.id,
							fullName: payroll.fullName,
							hoursWorked: payroll.totalHoursWorked.toLocaleString(),
							basePay: payroll.basePay.toLocaleString(),
							contribution: payroll.contribution.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							loans: payroll.totalLoansPayable.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							holidayPay: payroll.totalHolidayPay.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							netReceivable: payroll.netReceivable.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							printRows: ' '
						}))

						setRows(displayRows)
						props.setRawSummaryData(payrollData.details)
						props.setBankSummaryData(bankSummaryData)
						props.setPayrollSummaryData(payrollSummaryData)
						props.setLoading(false)
						props.setConfirmed(payrollData.isConfirmed)
					})
				} else if (response.status == 401 || response.status == 403) {
					history.push(paths.signin)
				}
			})
			.catch(() => {
				setRows([])
			})
	}, [props.renderSummary])

	if (rows.length === 0 && !props.loading) {
		return <StyledTableNoData columns={columns} />
	}

	if (props.loading) {
		return <StyledTableLoading columns={columns} />
	}

	return <StyledTable columns={columns} rows={rows} />
}

SummaryTable.propTypes = {
	renderSummary: PropTypes.number.isRequired,
	startDate: PropTypes.object.isRequired,
	endDate: PropTypes.object.isRequired,
	loading: PropTypes.bool.isRequired,
	setConfirmed: PropTypes.func.isRequired,
	setLoading: PropTypes.func.isRequired,
	setRawSummaryData: PropTypes.func.isRequired,
	setBankSummaryData: PropTypes.func.isRequired,
	setPayrollSummaryData: PropTypes.func.isRequired
}
