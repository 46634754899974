import { withStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const captionText = 'This is a system generated form. Validation is not necessary'

const useStyles = makeStyles(() => ({
	table: {
		minWidth: 600
	},
	boldFont: {
		fontWeight: 700
	}
}))

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white
	},
	body: {
		fontSize: 14
	},
	alignRight: {
		fontFamily: 'monospace'
	}
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover
		}
	}
}))(TableRow)

function renderColumnCell(key, value) {
	return <StyledTableCell key={key}>{value}</StyledTableCell>
}

function renderCell(key, value) {
	let tempValue = value ? value.toString().replace(/[,.]/g, '') : 0

	if (tempValue == 0 && key != 'printRows') {
		return (
			<StyledTableCell align="center" key={key}>
				-
			</StyledTableCell>
		)
	}

	return (
		<StyledTableCell className="mono" align={isNaN(tempValue) ? 'left' : 'right'} key={key}>
			{value}
		</StyledTableCell>
	)
}

function renderOptionalRow(optionalRow, classes) {
	if (optionalRow) {
		let tempValue = optionalRow.value.toString().replace(/[,.]/g, '')

		return (
			<TableRow className={classes.boldFont}>
				<TableCell rowSpan={0} />
				<TableCell className={classes.boldFont} colSpan={optionalRow.colSpan}>
					{optionalRow.name}
				</TableCell>
				<TableCell className={classes.boldFont} align={isNaN(tempValue) ? 'left' : 'right'}>
					{optionalRow.value}
				</TableCell>
			</TableRow>
		)
	}
}

export function StyledTable(props) {
	const classes = useStyles()

	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size={props.dense ? 'small' : 'medium'}>
				{props.showCaption ? <caption>{captionText}</caption> : null}
				<TableHead>
					<TableRow>{props.columns.map((column) => Object.entries(column).map(([key, value]) => renderColumnCell(key, value)))}</TableRow>
				</TableHead>
				<TableBody>
					{props.rows.map((row, index) => (
						<StyledTableRow key={index}>{Object.entries(row).map(([key, value]) => renderCell(key, value))}</StyledTableRow>
					))}
					{renderOptionalRow(props.optionalRow, classes)}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export function StyledTableLoading(props) {
	const classes = useStyles()

	return (
		<div>
			<TableContainer component={Paper}>
				<Table className={classes.table} size={props.dense ? 'small' : 'medium'}>
					<TableHead>
						<TableRow>{props.columns.map((column) => Object.entries(column).map(([key, value]) => renderColumnCell(key, value)))}</TableRow>
					</TableHead>
				</Table>
			</TableContainer>
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
			<Skeleton height={60} />
		</div>
	)
}

export function StyledTableNoData(props) {
	const classes = useStyles()

	return (
		<div>
			<TableContainer component={Paper}>
				<Table className={classes.table} size={props.dense ? 'small' : 'medium'}>
					<caption>No data found</caption>
					<TableHead>
						<TableRow>{props.columns.map((column) => Object.entries(column).map(([key, value]) => renderColumnCell(key, value)))}</TableRow>
					</TableHead>
				</Table>
			</TableContainer>
		</div>
	)
}

StyledTable.defaultProps = {
	dense: false,
	showCaption: false
}

StyledTable.StyledTable = {
	columns: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	dense: PropTypes.bool,
	optionalRow: PropTypes.object,
	showCaption: PropTypes.bool
}

StyledTableLoading.defaultProps = {
	dense: false
}

StyledTableLoading.StyledTable = {
	columns: PropTypes.array.isRequired,
	dense: PropTypes.bool
}

StyledTableNoData.defaultProps = {
	dense: false
}

StyledTableNoData.StyledTable = {
	columns: PropTypes.array.isRequired,
	dense: PropTypes.bool
}
