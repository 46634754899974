import { makeStyles } from '@material-ui/core/styles'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'

const useStyles = makeStyles((theme) => ({
	snackBar: {
		top: '78px',
		right: '10px'
	}
}))

export default function Toast(props) {
	const classes = useStyles()

	const handleClose = (event, reason) => {
		if (reason !== 'clickaway') props.setOpen(false)
	}

	return (
		<Snackbar className={classes.snackBar} open={props.isOpen} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
			<Alert onClose={handleClose} severity={props.severity}>
				{props.label}
			</Alert>
		</Snackbar>
	)
}

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

Toast.propTypes = {
	label: PropTypes.string.isRequired,
	severity: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired
}
