import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import clsx from 'clsx'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import LocalLibraryOutlinedIcon from '@material-ui/icons/LocalLibraryOutlined'
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import { paths } from '../../pages/routes'
import Hovertip from './hovertip'

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
	drawer: {
		flexShrink: 0,
		whiteSpace: 'nowrap'
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		[theme.breakpoints.down('xs')]: {
			width: window.innerWidth
		}
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: theme.spacing(7), // if this is changed, 'navMargin' should also be changed in all child parent pages
		[theme.breakpoints.down('xs')]: {
			width: theme.spacing(0)
		}
	},
	heading: {
		paddingTop: theme.spacing(2),
		paddingLeft: theme.spacing(2.5),
		color: '#888',
		fontWeight: '400'
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar // necessary for content to be below app bar
	},
	test: {
		backgroundColor: theme.palette.secondary.main
	}
}))

export default function NavigationBar(props) {
	const classes = useStyles()
	const history = useHistory()
	const [currentLocation, setCurrentLocation] = React.useState('Tracker')

	const attendanceMenuItems = [
		{
			label: 'Tracker',
			icon: <PermContactCalendarOutlinedIcon color={currentLocation == 'Tracker' ? 'primary' : 'default'} />,
			path: paths.attendanceTracker
		},
		{
			label: 'History',
			icon: <LocalLibraryOutlinedIcon color={currentLocation == 'History' ? 'primary' : 'default'} />,
			path: paths.attendanceHistory
		}
	]

	const payrollMenuItems = [
		{
			label: 'Summary',
			icon: <MonetizationOnOutlinedIcon color={currentLocation == 'Summary' ? 'primary' : 'default'} />,
			path: paths.payrollSummary
		},
		{
			label: 'Loans',
			icon: <AccountBalanceOutlinedIcon color={currentLocation == 'Loans' ? 'primary' : 'default'} />,
			path: paths.payrollLoans
		},
		{
			label: 'Employees',
			icon: <GroupOutlinedIcon color={currentLocation == 'Employees' ? 'primary' : 'default'} />,
			path: paths.payrollEmployees
		}
	]

	return (
		<div>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: props.drawerIsOpen,
					[classes.drawerClose]: !props.drawerIsOpen
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: props.drawerIsOpen,
						[classes.drawerClose]: !props.drawerIsOpen
					})
				}}>
				<div className={classes.toolbar} />
				{renderHeading('Attendance')}
				{renderMenuItem(attendanceMenuItems)}
				<Divider />
				{renderHeading('Payroll')}
				{renderMenuItem(payrollMenuItems)}
			</Drawer>
			<div className={classes.toolbar} />
		</div>
	)

	function renderHeading(headingText) {
		if (props.drawerIsOpen) {
			return (
				<Typography variant="h6" className={classes.heading}>
					{headingText}
				</Typography>
			)
		}
	}

	function renderMenuItem(list) {
		return (
			<List>
				{list.map((menuItem) => (
					<div>
						<Hovertip key={menuItem.label} label={menuItem.label} placement={'right'} isVisible={!props.drawerIsOpen}>
							<ListItem
								button
								onClick={() => {
									history.push(menuItem.path)
									props.setDrawerOpen(false)
									setCurrentLocation(menuItem.label)
								}}>
								<ListItemIcon>{menuItem.icon}</ListItemIcon>
								<ListItemText primary={menuItem.label} />
							</ListItem>
						</Hovertip>
					</div>
				))}
			</List>
		)
	}
}

NavigationBar.propTypes = {
	drawerIsOpen: PropTypes.bool.isRequired,
	setDrawerOpen: PropTypes.func.isRequired
}
