import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
	cardMargin: {
		marginBottom: theme.spacing(3),
		minWidth: (props) => props.minWidth
	},
	header: {
		display: 'flex',
		padding: theme.spacing(3)
	},
	body: {
		padding: theme.spacing(3),
		textAlign: 'center'
	},
	rightAlign: {
		marginLeft: 'auto',
		marginTop: 'auto',
		marginBottom: 'auto'
	}
}))

export default function CardWithContent(props) {
	const classes = useStyles(props)

	return (
		<Paper elevation={5} className={classes.cardMargin}>
			<div className={classes.header}>
				<div>
					<Typography variant="h5" align={'left'}>
						{props.title}
					</Typography>
					<Typography className={classes.extraPadding} variant="subtitle2">
						{props.subTitle}
					</Typography>
				</div>
				<div className={classes.rightAlign}>{props.actionButtons}</div>
			</div>
			<Divider />
			<div className={classes.body}>{props.children}</div>
		</Paper>
	)
}

CardWithContent.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	actionButtons: PropTypes.element,
	children: PropTypes.any.isRequired,
	minWidth: PropTypes.string
}
