export const myTheme = {
	palette: {
		type: 'light',
		primary: {
			main: '#2c7aaf',
			light: '#66a9e1',
			dark: '#004e7f',
			contrastText: '#ffffff'
		},
		secondary: {
			main: '#6d4f99',
			light: '#9d7cca',
			dark: '#3f266a',
			contrastText: '#ffffff'
		},
		background: {
			default: '#f0f0f0'
		},
		text: {
			primary: '#333',
			secondary: '#666',
			disabled: '#aaa',
			hint: '#888'
		}
	},
	typography: {
		fontFamily: ['Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontWeight: 300,
			fontSize: '6rem',
			lineHeight: 1.167,
			letterSpacing: '-0.01562em'
		},
		h2: {
			fontWeight: 300,
			fontSize: '3.75rem',
			lineHeight: 1.2,
			letterSpacing: '-0.00833em'
		},
		h3: {
			fontWeight: 400,
			fontSize: '3rem',
			lineHeight: 1.167,
			letterSpacing: '0em'
		},
		h4: {
			fontWeight: 400,
			fontSize: '2.125rem',
			lineHeight: 1.235,
			letterSpacing: '0.00735em'
		},
		h5: {
			fontWeight: 400,
			fontSize: '1.85rem',
			lineHeight: 1.334,
			letterSpacing: '0em'
		},
		h6: {
			fontWeight: 500,
			fontSize: '1.25rem',
			lineHeight: 1.6,
			letterSpacing: '0.0075em'
		},
		subtitle1: {
			fontWeight: 350,
			fontSize: '1rem',
			lineHeight: 1.75,
			letterSpacing: '0.00938em',
			color: '#999'
		},
		subtitle2: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: 1.57,
			letterSpacing: '0.00714em',
			color: '#999'
		},
		body1: {
			fontWeight: 400,
			fontSize: '1rem',
			lineHeight: 1.5,
			letterSpacing: '0.00938em'
		},
		body2: {
			fontWeight: 400,
			fontSize: '0.875rem',
			lineHeight: 1.43,
			letterSpacing: '0.01071em'
		},
		button: {
			fontWeight: 500,
			fontSize: '0.875rem',
			lineHeight: 1.75,
			letterSpacing: '0.02857em',
			textTransform: 'uppercase'
		},
		caption: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: 1.66,
			letterSpacing: '0.03333em'
		},
		overline: {
			fontWeight: 400,
			fontSize: '0.75rem',
			lineHeight: 2.66,
			letterSpacing: '0.08333em',
			textTransform: 'uppercase'
		}
	}
}
