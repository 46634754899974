import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import { fetchEmployeeAndLoanData, sortDataForDisplay } from './loanLogic'
import { paths } from '../../../../pages/routes'
import { StyledTable, StyledTableLoading, StyledTableNoData } from '../../../.global/styledTable'

export default function LoansTable(props) {
	const [rows, setRows] = useState([])
	const [loanList, setLoanList] = useState([])
	const history = useHistory()

	const columns = [{ name: 'ID' }, { name: 'Full name' }, { name: 'Loan type' }, { name: 'Loan amount' }, { name: 'Amount remaining' }, { name: 'Loan date' }, { name: 'Due date' }]

	useEffect(() => {
		props.setLoadTableData(true)
	}, [])

	useEffect(() => {
		if (props.loadTableData) {
			fetchEmployeeAndLoanData()
				.then(({ employees, loans }) => {
					props.setEmployeeList(employees)
					props.setEmployeeCodeList(employees.map((employee) => employee.employeeCode))

					if (loans) {
						setLoanList(loans)
						setRows(sortDataForDisplay(employees, loans))
					}

					props.setLoadTableData(false)
				})
				.catch((error) => {
					if (error.message === 'Invalid session') {
						history.push(paths.signin)
					}

					props.setLoadTableData(false)
				})
		}
	}, [props.loadTableData])

	useEffect(() => {
		if (loanList.length > 0) {
			setRows(sortDataForDisplay(props.employeeList, loanList, props.filterTableCode))
		}
	}, [props.filterTableCode])

	if (rows.length === 0 && !props.loadTableData) {
		return <StyledTableNoData columns={columns} />
	}

	if (props.loadTableData) {
		return <StyledTableLoading columns={columns} />
	}

	return <StyledTable columns={columns} rows={rows} />
}

LoansTable.propTypes = {
	loadTableData: PropTypes.bool.isRequired,
	setLoadTableData: PropTypes.func.isRequired,
	employeeList: PropTypes.array.isRequired,
	setEmployeeList: PropTypes.func.isRequired,
	setEmployeeCodeList: PropTypes.func.isRequired,
	filterTableCode: PropTypes.string.isRequired
}
