import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles(() => ({
	flex: {
		display: 'inline'
	}
}))

export default function ButtonDropdown(props) {
	const classes = useStyles()
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen)
	}

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return
		}

		setOpen(false)
	}

	return (
		<div className={classes.flex}>
			<Button className={props.className} variant="contained" color="secondary" ref={anchorRef} onClick={handleToggle} startIcon={<PrintOutlinedIcon />} endIcon={<ArrowDropDownIcon />}>
				Print
			</Button>
			<Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList>
									{props.buttonList.map((button, index) => (
										<MenuItem
											key={index}
											onClick={() => {
												button.onClick(button.printId)
												setOpen(false)
											}}>
											{button.name}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)
}

ButtonDropdown.propTypes = {
	buttonList: PropTypes.array.isRequired,
	className: PropTypes.string
}
