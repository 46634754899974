import NumberFormat from 'react-number-format'
import PropTypes from 'prop-types'
import React from 'react'
import TextField from '@material-ui/core/TextField'

export default function TextFeildCurrency(props) {
	return (
		<TextField
			className={props.classes}
			onChange={props.onChange}
			value={props.value}
			InputProps={{ inputComponent: NumberFormatCustom }}
			label={props.label}
			error={props.error}
			helperText={props.helperText}
			variant="outlined"
		/>
	)
}

TextFeildCurrency.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string,
	error: PropTypes.bool.isRequired,
	helperText: PropTypes.string.isRequired,
	classes: PropTypes.any
}

function NumberFormatCustom(props) {
	const { inputRef, onChange, ...other } = props

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						value: values.value
					}
				})
			}}
			thousandSeparator
			allowNegative={false}
			decimalScale={2}
			isNumericString
		/>
	)
}

NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
}
