import { getHeaders } from './adapterHelper'

export default function recordAttendance(data) {
	var myHeaders = getHeaders()

	var myBody = new URLSearchParams()
	myBody.append('logType', data.logType)
	myBody.append('employeeCode', data.employeeCode)
	myBody.append('Content-Type', 'application/json; charset=utf-8')

	var request = {
		method: 'POST',
		headers: myHeaders,
		body: myBody
	}

	return fetch('/api/attendance', request)
}
