import differenceInCalendarWeeks from 'date-fns/differenceInCalendarWeeks'
import format from 'date-fns/format'

import { createLoan } from '../../../../../adapters/loanAdapter'

//export async function fetchCreateLoan() {}

const loanBarriers = {
	minCA: 10,
	minCAPrint: '10',
	maxCA: 1000,
	maxCAPrint: '1,000',
	minBank: 1000,
	minBankPrint: '1,000',
	maxBank: 100000,
	maxBankPrint: '100,000'
}

export function doesEmployeeExist(employeeCodeList, employeeCode) {
	if (employeeCodeList.includes(employeeCode)) return true
	else return false
}

export function getEmployeeName(employeeList, employeeCodeList, employee) {
	if (employeeCodeList.includes(employee.code)) {
		const employeeDetails = employeeList.find((isEmployee) => {
			return isEmployee.employeeCode == employee.code
		})

		return employeeDetails.fullName
	}

	return ''
}

export function isSubmitable(loanDetails, employeeCodeList, employee, loanTypes) {
	if (doesEmployeeExist(employeeCodeList, employee.code)) {
		return isLoanAmountAcceptable(loanDetails, loanTypes)
	}

	return false
}

export function isLoanAmountAcceptable(loanDetails, loanTypes, newAmount) {
	const amount = newAmount ? newAmount : loanDetails.amount
	const { cashAdvance } = loanTypes
	const { minCA, maxCA, minBank, maxBank } = loanBarriers

	if (loanDetails.type === cashAdvance.value) {
		if (amount >= minCA && amount <= maxCA) {
			return true
		}
	} else if (loanDetails.type) {
		if (amount >= minBank && amount <= maxBank) {
			return true
		}
	}

	return false
}

export function setLoanTypeWithDefaults(event, setPayAtNextPayDay, loanDetails, setLoanDetails, setDueDate, loanTypes, employee, setEmployee) {
	const { cashAdvance, sss, hdmf } = loanTypes
	const loanType = event.target.value
	const { minCAPrint, maxCAPrint, minBankPrint, maxBankPrint } = loanBarriers

	let loanLabel

	if (loanType === sss.value || loanType === hdmf.value) {
		setPayAtNextPayDay(false)
		loanLabel = `Loan amount (min ${minBankPrint}) (max ${maxBankPrint})`
	} else if (loanType === cashAdvance.value) {
		setPayAtNextPayDay(true)
		loanLabel = `Loan amount (min ${minCAPrint}) (max ${maxCAPrint})`
	}

	setLoanDetails({ ...loanDetails, type: loanType, textFieldLabel: loanLabel, amount: '' })

	setDueDate(nextPayDay())
	setEmployee({
		...employee,
		hasError: false,
		helperText: ''
	})
}

function nextPayDay() {
	const payDay = 4 // Thursday
	const today = new Date()

	return new Date().setDate(today.getDate() + ((7 + payDay - today.getDay() - 1) % 7) + 1)
}

export function calculatePaymentDetails(dueDate, loanAmount, payAtNextPayDay, setWeeksUntilPaid, setWeeklyPayments) {
	var amountOfWeeks = 1
	var paymentAmounts = 0

	if (!payAtNextPayDay) {
		amountOfWeeks = differenceInCalendarWeeks(dueDate, new Date(), { weekStartsOn: 4 })
		paymentAmounts = (loanAmount / amountOfWeeks).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}

	setWeeksUntilPaid(amountOfWeeks)
	setWeeklyPayments(paymentAmounts)
}

export async function postCreateLoan(employeeCode, type, totalLoan, dueDate, payAtNextPayDay, incrementalPaymentAmount, employee, setEmployee, setLoanDetails) {
	const formattedDueDate = format(dueDate, 'yyyy-MM-dd')

	const result = await createLoan({
		employeeCode: employeeCode,
		type: type,
		totalLoan: totalLoan,
		dueDate: formattedDueDate,
		payInFullOnCurrentPayPeriod: payAtNextPayDay,
		incrementalPaymentAmount: incrementalPaymentAmount
	})

	if (result.status == 401 || result.status == 403) {
		throw new Error('Invalid session')
	} else if (!result.ok) {
		const error = await result.text()

		setEmployee({
			...employee,
			hasError: true,
			helperText: error
		})

		throw new Error(`${error}`)
	} else {
		setEmployee({
			code: '',
			hasError: false,
			helperText: ''
		})
		setLoanDetails({
			type: '',
			amount: '',
			hasError: false,
			helperText: ''
		})
	}
}
