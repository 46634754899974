import Grid from '@material-ui/core/Grid'
import React, { useState, useEffect } from 'react'

import ScreenOverlay from '../components/.global/screenOverlay'
import SigninForm from '../components/signin/signInFormContainer'

export default function Signin() {
	const [isSending, setIsSending] = useState(false)

	useEffect(() => {
		document.body.style.background = 'linear-gradient(55deg, #2c7aaf 50%, #66a9e1 50%) fixed'
	}, [])

	return (
		<div>
			<ScreenOverlay isOpen={isSending} />
			<Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '95vh' }}>
				<Grid item xl lg={4} md={3} sm={1} xs />
				<Grid item xl={3} lg={4} md={6} sm={10} xs={12}>
					<SigninForm setIsSending={setIsSending} />
				</Grid>
				<Grid item xl lg={4} md={3} sm={1} xs />
			</Grid>
		</div>
	)
}
