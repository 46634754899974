import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import format from 'date-fns/format'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import { StyledTable } from '../../../../.global/styledTable'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(3),
		textAlign: 'center'
	},
	paddingBottom: {
		paddingBottom: theme.spacing(4)
	}
}))

export default function PrintablePayrollSummary(props) {
	const classes = useStyles()
	const title = `${props.companyName} | Payroll summary for ${format(props.startDate, 'yyyy MMMM do')} to ${format(props.endDate, 'MMMM do')}`
	const printSummaryColumns = [
		{ name: 'ID' },
		{ name: 'Full name' },
		{ name: 'Hours worked' },
		{ name: 'Base pay' },
		{ name: 'Contribution' },
		{ name: 'Loan(s)' },
		{ name: 'Holiday Pay' },
		{ name: 'Net Receivable' },
		{ name: 'Sign here' }
	]

	return (
		<Box id="printPayrollSummary" display="none" displayPrint="block" className={classes.container}>
			<div className={classes.container}>
				<Typography className={classes.paddingBottom} variant="h6">
					{title}
				</Typography>
				<StyledTable columns={printSummaryColumns} rows={props.payrollSummaryData} dense={true} showCaption={true} />
			</div>
		</Box>
	)
}

PrintablePayrollSummary.propTypes = {
	companyName: PropTypes.string.isRequired,
	payrollSummaryData: PropTypes.array.isRequired,
	startDate: PropTypes.object.isRequired,
	endDate: PropTypes.object.isRequired
}
