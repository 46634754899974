import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'

import HomeRouter from './homeRoutes'
import NavigationBar from '../../../components/.global/navigationBar'
import TitleBar from '../../../components/.global/titleBar'

const useStyles = makeStyles((theme) => ({
	print: {
		marginLeft: theme.spacing(7),
		'@media screen': {
			display: 'none'
		},
		'@media print': {
			position: 'fixed',
			bottom: 0
		}
	}
}))

export default function HomeContainer() {
	const [drawerIsOpen, setDrawerOpen] = useState(false)
	const classes = useStyles()

	useEffect(() => {
		document.body.style.background = '#f0f0f0'
	}, [])

	return (
		<div>
			<TitleBar drawerIsOpen={drawerIsOpen} setDrawerOpen={setDrawerOpen} />
			<NavigationBar drawerIsOpen={drawerIsOpen} setDrawerOpen={setDrawerOpen} />
			<HomeRouter drawerIsOpen={drawerIsOpen} />
			<div className={classes.print}>Created by https://sweldo.app/ | Contact sweldopayroll@gmail.com for enquires</div>
		</div>
	)
}

function OutsideAlerter(props) {
	// Outside alter is used to close the Navigation bar drawer when a mouse click is detected outside of its element
	const wrapperRef = useRef(null)
	useOutsideAlerter(wrapperRef, props)

	return <div ref={wrapperRef}>{props.children}</div>
}

function useOutsideAlerter(ref, props) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				props.setDrawerOpen(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}

OutsideAlerter.propTypes = {
	children: PropTypes.element.isRequired,
	setDrawerOpen: PropTypes.func
}
