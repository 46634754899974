import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import { paths } from '../../routes'
import HomeContent from './homeContent'
// Attendance
import Tracker from '../attendance/tracker'
import History from '../attendance/history'
// Payroll
import Employees from '../payroll/employees'
import Loans from '../payroll/loans'
import Summary from '../payroll/summary'
// Other
import NotFound from '../../notFound'

export default function HomeRouter(props) {
	return (
		<HomeContent drawerIsOpen={props.drawerIsOpen}>
			<Switch>
				<Route path={paths.attendanceTracker}>
					<Tracker />
				</Route>
				<Route path={paths.attendanceHistory}>
					<History />
				</Route>
				<Route path={paths.payrollSummary}>
					<Summary />
				</Route>
				<Route path={paths.payrollLoans}>
					<Loans />
				</Route>
				<Route path={paths.payrollEmployees}>
					<Employees />
				</Route>
				<Route>
					<NotFound fromHome={true} />
				</Route>
			</Switch>
		</HomeContent>
	)
}

HomeRouter.propTypes = {
	drawerIsOpen: PropTypes.bool.isRequired
}
