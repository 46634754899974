import { getHeaders } from './adapterHelper'

export default function getEmployees() {
	var myHeaders = getHeaders()

	var request = {
		method: 'GET',
		headers: myHeaders
	}

	return fetch('/api/employees', request)
}
