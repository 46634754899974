import signIn from '../../adapters/authAdapter'

export async function submitSignIn(username, password) {
	const result = await signIn(username, password)

	if (!result.ok) {
		throw new Error(`An error has occured: ${result.status}`)
	} else {
		return await result.json()
	}
}
