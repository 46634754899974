import Grid from '@material-ui/core/Grid'
import React from 'react'

import EmployeeContainer from '../../../components/home/payroll/employees/employeesContainer'

export default function Employees() {
	return (
		<Grid container spacing={0} direction="column" alignItems="center" justify="center">
			<Grid item xs />
			<Grid item xs={'auto'}>
				<EmployeeContainer />
			</Grid>
			<Grid item xs></Grid>
		</Grid>
	)
}
