import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import React from 'react'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
})
export default function Modal(props) {
	const useStyles = makeStyles((theme) => ({
		cancelButton: {
			color: theme.palette.grey[500]
		}
	}))

	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

	return (
		<div>
			<Dialog open={props.modalIsOpen} onClose={() => props.setModalState(false)} TransitionComponent={Transition} fullScreen={fullScreen}>
				<DialogTitle onClose={() => props.setModalState(false)}>{props.title}</DialogTitle>
				<DialogContent dividers>
					{props.message1 ? <Typography gutterBottom>{props.message1}</Typography> : null}
					{props.message2 ? <Typography gutterBottom>{props.message2}</Typography> : null}
					{props.message3 ? <Typography gutterBottom>{props.message3}</Typography> : null}
					{props.message4 ? <Typography gutterBottom>{props.message4}</Typography> : null}
				</DialogContent>
				<DialogActions>
					{renderCancelButton()}
					{renderStandardButton()}
				</DialogActions>
			</Dialog>
		</div>
	)

	function renderCancelButton() {
		if (props.showCancel) {
			return (
				<Button onClick={() => props.setModalState(false)} className={classes.cancelButton}>
					Cancel
				</Button>
			)
		}
	}

	function renderStandardButton() {
		return (
			<Button
				onClick={() => {
					props.setModalState(false)
					if (props.buttonAction) props.buttonAction()
				}}
				color="primary"
				autoFocus>
				{props.buttonText}
			</Button>
		)
	}
}

Modal.propTypes = {
	modalIsOpen: PropTypes.bool.isRequired,
	setModalState: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired,
	buttonAction: PropTypes.func,
	showCancel: PropTypes.bool,
	message1: PropTypes.string,
	message2: PropTypes.string,
	message3: PropTypes.string,
	message4: PropTypes.string
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />
})

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions)
