import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'

import { paths } from './routes'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(3),
		backgroundColor: 'white',
		textAlign: 'center'
	}
}))

export default function NotFound(props) {
	const classes = useStyles()

	useEffect(() => {
		if (!props.fromHome) {
			document.body.style.background = 'linear-gradient(55deg, #2c7aaf 50%, #66a9e1 50%) fixed'
		}
	})

	return (
		<Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '30vh' }}>
			<Grid item xl lg={4} md={3} sm={1} xs />
			<Grid item xl={3} lg={4} md={6} sm={10} xs={12}>
				<Paper elevation={5}>
					<div className={classes.container}>
						<Typography variant={'h3'}>PAGE NOT FOUND</Typography>
						{!props.fromHome ? (
							<Link href={paths.signin}>
								<Typography variant={'h6'}>Go to sign in page</Typography>
							</Link>
						) : null}
					</div>
				</Paper>
			</Grid>
			<Grid item xl lg={4} md={3} sm={1} xs />
		</Grid>
	)
}

NotFound.propTypes = {
	fromHome: PropTypes.bool.isRequired
}
