import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'

import { getEmployeeBarCodes, getDefaultLogType, logTime, getLogType } from './trackerLogic'
import { paths } from '../../../../pages/routes'
import Modal from '../../../.global/modal'

const useStyles = makeStyles((theme) => ({
	form: {
		margin: theme.spacing(3),
		textAlign: 'center'
	},
	marginTop: {
		marginTop: theme.spacing(3),
		width: '100%'
	},
	radio: {
		display: 'inline-block',
		verticalAlign: 'top'
	},
	errorButton: {
		backgroundColor: theme.palette.error.main,
		color: 'white'
	}
}))

export default function TrackerForm(props) {
	const classes = useStyles()
	const history = useHistory()
	const [logType, setLogType] = useState(() => getDefaultLogType())
	const [errorState, setErrorState] = useState(false)
	const [employeeBarCodeList, setEmployeeBarCodeList] = useState([])
	const [employeeDetails, setEmployeeDetailsList] = useState([])
	const [employeeCode, setEmployeeCode] = useState('')
	const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)
	const [errorLoadingPage, setErrorLoadingPage] = useState(false)

	useEffect(() => {
		getEmployeeBarCodes()
			.then((jsonData) => {
				const employeeData = jsonData
				setEmployeeBarCodeList(employeeData.map((employee) => employee.employeeCode))
				setEmployeeDetailsList(
					employeeData.map((employee) => ({
						employeeCode: employee.employeeCode,
						employeeNumber: employee.employeeNumber,
						fullName: employee.fullName
					}))
				)
			})
			.catch((error) => {
				if (error.message === 'Invalid session') {
					history.push(paths.signin)
				} else {
					prepareToast('ERROR RELOAD PAGE', 'error')
					setErrorLoadingPage(true)
					setErrorModalIsOpen(true)
				}
			})
	}, [])

	function handleSubmit(event) {
		event.preventDefault()
		props.setIsSending(true)

		logTime(employeeBarCodeList, employeeCode, logType)
			.then((resultJSON) => {
				prepareToast('Time logged successfully', 'success')
				setErrorState(false)
				addToHistoryTableSuccess(resultJSON)
			})
			.catch((error) => {
				if (error.message === 'Invalid session') {
					history.push(paths.signin)
				} else if (error.message === 'Employee does not exist') {
					prepareToast('Employee code does not exist', 'error')
					setErrorState(true)
					addToHistoryTableFailed('Invalid ID')
				} else {
					prepareToast('Error submitting time', 'error')
					setErrorModalIsOpen(true)
					setErrorState(true)
					setErrorLoadingPage(true)
					addToHistoryTableFailed('ERROR')
				}
			})

		setEmployeeCode('')
		setLogType(getDefaultLogType())
		props.setIsSending(false)
	}

	function prepareToast(label, severity) {
		props.setToastLabel(label)
		props.setToastSeverity(severity)
		props.openToast(true)
	}

	function addToHistoryTableFailed(message) {
		const history = {
			id: message,
			fullName: '',
			type: getLogType(logType),
			time: '',
			success: 'FAILED'
		}

		props.setAttendanceHistory([history].concat(props.attendanceHistory.slice(0, 50)))
	}

	function addToHistoryTableSuccess(resultJSON) {
		const employee = employeeDetails.find((employee) => {
			return employee.employeeCode == employeeCode
		})

		const history = {
			id: employee.employeeNumber,
			fullName: employee.fullName,
			type: getLogType(logType),
			time: resultJSON.pmLogOut || resultJSON.pmLogIn || resultJSON.amLogOut || resultJSON.amLogIn,
			success: 'Logged successfully'
		}

		props.setAttendanceHistory([history].concat(props.attendanceHistory))
	}

	function storeEmployeeCode(event) {
		setEmployeeCode(event.target.value)
	}

	return (
		<form className={classes.form} onSubmit={handleSubmit}>
			<FormControl>
				<RadioGroup className={classes.radio} id="logType" row defaultValue={logType} value={logType} onChange={(e) => setLogType(e.target.value)}>
					<FormControlLabel id="amlogin" value="amlogin" control={<Radio color="primary" />} label="Morning in" labelPlacement="top" />
					<FormControlLabel id="amlogout" value="amlogout" control={<Radio color="primary" />} label="Morning out" labelPlacement="top" />
					<FormControlLabel id="pmlogin" value="pmlogin" control={<Radio color="primary" />} label="Afternoon in" labelPlacement="top" />
					<FormControlLabel id="pmlogout" value="pmlogout" control={<Radio color="primary" />} label="Afternoon out" labelPlacement="top" />
				</RadioGroup>
				<TextField
					className={classes.marginTop}
					onChange={storeEmployeeCode}
					value={employeeCode}
					id="employeeCode"
					label="Employee code"
					variant="outlined"
					autoFocus
					inputProps={{ maxLength: 10 }}
					error={errorState}
					helperText={errorState ? 'Please enter a valid employee code' : null}
				/>
				{getFormButton(props.isSending)}
			</FormControl>
			<Modal modalIsOpen={errorModalIsOpen} setModalState={setErrorModalIsOpen} title="Error" buttonText="Close" message1="Error loading page. Please reload" />
		</form>
	)

	function getFormButton(isSending) {
		if (errorLoadingPage) {
			return (
				<Button
					className={`${classes.marginTop} ${classes.errorButton}`}
					onClick={() => {
						setErrorModalIsOpen(true)
					}}
					id="errorLoadingAttendance"
					variant="contained">
					Reload page
				</Button>
			)
		}

		if (employeeCode.length == 0) {
			return (
				<Button className={classes.marginTop} id="record" type="submit" variant="contained" color="primary" disabled>
					Enter Employee ID
				</Button>
			)
		}

		if (!isSending) {
			return (
				<Button className={classes.marginTop} id="record" type="submit" variant="contained" color="primary">
					Record
				</Button>
			)
		} else {
			return (
				<Button className={classes.marginTop} id="record" type="submit" variant="contained" color="primary" disabled>
					Sending
				</Button>
			)
		}
	}
}

TrackerForm.propTypes = {
	isSending: PropTypes.bool.isRequired,
	setIsSending: PropTypes.func.isRequired,
	openToast: PropTypes.func.isRequired,
	setToastLabel: PropTypes.func.isRequired,
	setToastSeverity: PropTypes.func.isRequired,
	attendanceHistory: PropTypes.array.isRequired,
	setAttendanceHistory: PropTypes.func.isRequired
}
