import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import format from 'date-fns/format'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

import { StyledTable } from '../../../../.global/styledTable'

const accountNumber = '310 01-13637-5'
const writeHereSpacer = '_________________________'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(3),
		textAlign: 'center'
	},
	paddingBottom: {
		paddingBottom: theme.spacing(4)
	},
	paddingLeft: {
		paddingLeft: theme.spacing(4)
	},
	maxWidth: {
		maxWidth: '700px',
		margin: 'auto'
	}
}))

export default function PrintableBankSummary(props) {
	const classes = useStyles()
	const title = `Payroll for the cut-off date: ${format(props.payrollCutOff, 'MMMM do')}`
	const printBankColumns = [{ name: 'EMPLOYEE NAME' }, { name: 'ACCOUNT #' }, { name: 'AMOUNT' }]

	return (
		<Box id="printBankSummary" display="none" displayPrint="block" className={classes.container}>
			<div className={classes.container}>
				<Typography variant="h6">{props.companyName}</Typography>
				<Typography className={classes.paddingBottom} variant="h6">
					{title}
				</Typography>
				<div className={`${classes.maxWidth} ${classes.paddingBottom}`}>
					<StyledTable columns={printBankColumns} rows={props.bankSummaryData} dense={true} optionalRow={{ colSpan: '1', name: 'TOTAL AMOUNT', value: calculateTotalReceivable() }} />
				</div>
				{renderAuthorizationText()}
				{renderSignHereText()}
			</div>
		</Box>
	)

	function calculateTotalReceivable() {
		// iterate through bankSummaryData, remove commas, parse as float, sum together and return total
		return props.bankSummaryData
			.reduce((totalSum, bankRow) => totalSum + parseFloat(bankRow.amount.replace(/[,]/g, '')), 0)
			.toFixed(2)
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}

	function renderAuthorizationText() {
		return (
			<div className={classes.alignWithTable}>
				<Typography variant="body1">This is to authorize UCPB Savings Bank - Pili Branch to debit from the savings account:</Typography>
				<Typography className={classes.paddingLeft} variant="body1">
					Account name: {props.companyName}
				</Typography>
				<Typography className={classes.paddingLeft} variant="body1">
					Account number: {accountNumber}
				</Typography>
				<Typography className={classes.paddingBottom} variant="body1">
					and credit to the account details and the respective amounts of the table above.
				</Typography>
			</div>
		)
	}

	function renderSignHereText() {
		return (
			<Grid className={classes.marginLeft} container alignItems="center" justify="center">
				{renderWriteHereLine('Prepared by:')}
				{renderWriteHereLine('Authorized by:')}
				{renderWriteHereLine('Received by:')}
				{renderWriteHereLine('Date received:')}
				{renderWriteHereLine(writeHereSpacer)}
				{renderWriteHereLine(writeHereSpacer)}
				{renderWriteHereLine(writeHereSpacer)}
				{renderWriteHereLine(writeHereSpacer)}
			</Grid>
		)
	}

	function renderWriteHereLine(text) {
		return (
			<Grid item xs={3}>
				<Typography className={classes.paddingBottom} variant="body1">
					{text}
				</Typography>
			</Grid>
		)
	}
}

PrintableBankSummary.propTypes = {
	companyName: PropTypes.string.isRequired,
	bankSummaryData: PropTypes.array.isRequired,
	payrollCutOff: PropTypes.object.isRequired
}
