import format from 'date-fns/format'
import { getHeaders } from './adapterHelper'

export default function getAttendanceHistory(startDate, endDate) {
	var myHeaders = getHeaders()

	var request = {
		method: 'GET',
		headers: myHeaders
	}

	//expected date format: 2021-01-25
	const dateFormat = 'yyyy-MM-dd'
	const startDateFormated = format(startDate, dateFormat)
	const endDateFormated = format(endDate, dateFormat)

	return fetch(`/api/summary/attendance?startDate=${startDateFormated}&endDate=${endDateFormated}`, request)
}
