export default function signIn(username, password) {
	var myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
	myHeaders.append('Accept', 'application/json')

	var myBody = new URLSearchParams()
	myBody.append('username', username)
	myBody.append('password', password)

	var request = {
		method: 'POST',
		headers: myHeaders,
		body: myBody
	}

	return fetch('/api/signin', request)
}
