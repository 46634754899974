import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import MuiAlert from '@material-ui/lab/Alert'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import LogRocket from 'logrocket'

import { paths } from '../../pages/routes'
import { submitSignIn } from './signinLogic'

import logo from '../../.assets/logo_text_below.png'

const useStyles = makeStyles((theme) => ({
	form: {
		padding: theme.spacing(3),
		backgroundColor: 'white',
		textAlign: 'center',
		maxWidth: '500px'
	},
	formField: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	submitButton: {
		width: '100%',
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(1)
	},
	error: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(4)
	},
	logo: {
		height: '150px',
		marginBottom: '30px'
	}
}))

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function SigninForm(props) {
	const classes = useStyles()
	const history = useHistory()
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [errorState, setErrorState] = useState(false)

	function handleSubmit(event) {
		event.preventDefault()
		props.setIsSending(true)

		submitSignIn(username, password)
			.then((result) => {
				document.cookie = `username=${result.userData.username}; path=/`
				document.cookie = `site=${result.userData.site}; path=/`
				document.cookie = `role=${result.userData.role}; path=/`

				LogRocket.identify('THE_USER_ID_IN_YOUR_APP', {
					name: result.userData.username,
					site: result.userData.site,
					role: result.userData.role
				})

				history.push(paths.attendanceTracker)
			})
			.catch(() => {
				setErrorState(true)
				setPassword('')
				props.setIsSending(false)
			})
	}

	return (
		<Paper elevation={5}>
			<form className={classes.form} onSubmit={handleSubmit}>
				<img className={classes.logo} src={logo} alt="Sweldo" />
				{errorState ? (
					<Alert className={classes.error} severity="error">
						Incorrect username or password
					</Alert>
				) : null}
				<TextField
					className={classes.formField}
					onChange={(e) => setUsername(e.target.value)}
					value={username}
					variant="outlined"
					id="username"
					label="Username"
					autoFocus
					error={errorState}
					required
				/>
				<TextField
					className={classes.formField}
					onChange={(e) => setPassword(e.target.value)}
					value={password}
					variant="outlined"
					label="Password"
					type="password"
					id="password"
					error={errorState}
					required
				/>
				<Button className={classes.submitButton} type="submit" variant="contained" color="primary">
					Sign in
				</Button>
			</form>
		</Paper>
	)
}

SigninForm.propTypes = {
	setIsSending: PropTypes.func.isRequired
}
