import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'

import CardWithContent from '../../../.global/cardWithContent'
import DateSelect from '../../../.global/dateSelect'
import HistoryTable from './historyTable'

const useStyles = makeStyles((theme) => ({
	filters: {
		display: 'flex'
	},
	centreDiv: {
		textAlign: 'center',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	field: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1)
	},
	marginLeft: {
		marginLeft: theme.spacing(1)
	},
	marginRight: {
		marginRight: theme.spacing(1)
	},
	dateSelect: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	}
}))

export default function HistoryContainer() {
	const classes = useStyles()
	const [loading, setLoading] = useState(true)
	const [historyDate, setHistoryDate] = useState(new Date())
	const [employeeFilter, setEmployeeFilter] = useState('')
	const [displayGaps, setDisplayGaps] = useState(true)

	return (
		<CardWithContent title={'Attendance History'} minWidth={'800px'}>
			<div className={classes.filters}>
				<DateSelect label={'Date'} value={historyDate} onChange={setHistoryDate} disableFuture={true} optionalClass={classes.dateSelect} />
				<TextField
					className={`${classes.field} ${classes.marginLeft} ${classes.marginRight}`}
					onChange={(e) => {
						setEmployeeFilter(e.target.value)
					}}
					value={employeeFilter}
					label="Employee search"
					variant="outlined"
				/>
			</div>
			<div className={classes.centreDiv}>
				<FormControlLabel control={<Checkbox checked={displayGaps} onChange={(e) => setDisplayGaps(e.target.checked)} color="primary" />} label="Only show gaps" />
			</div>
			<HistoryTable loading={loading} setLoading={setLoading} date={historyDate} employeeFilter={employeeFilter} displayGaps={displayGaps} />
		</CardWithContent>
	)
}
