import { CSVLink } from 'react-csv'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import endOfWeek from 'date-fns/endOfWeek'
import format from 'date-fns/format'
import GetAppIcon from '@material-ui/icons/GetApp'
import React, { useState } from 'react'
import startOfWeek from 'date-fns/startOfWeek'
import subDays from 'date-fns/subDays'

import { paths } from '../../../../pages/routes'
import ButtonDropdown from '../../../.global/buttonDropdown'
import CardWithContent from '../../../.global/cardWithContent'
import completeSummary from '../../../../adapters/completeSummaryAdapter'
import Modal from '../../../.global/modal'
import PaySlips from './printable/payslips'
import PrintableBankSummary from './printable/bankSummary'
import PrintablePayrollSummary from './printable/payrollSummary'
import ScreenOverlay from '../../../.global/screenOverlay'
import SummaryTable from './summaryTable'
import Toast from '../../../.global/toast'

const companyName = 'GRASS ART COLLECTION, INC.'

const useStyles = makeStyles((theme) => ({
	topButtons: {
		marginLeft: theme.spacing(3),
		height: '41px'
	},
	removeDecoration: {
		textDecoration: 'none'
	}
}))

export default function SummaryContainer() {
	const classes = useStyles()
	const history = useHistory()
	const [renderSummary, setRenderSummary] = useState(0)
	const [startDate] = useState(subDays(startOfWeek(new Date()), 3))
	const [endDate] = useState(subDays(endOfWeek(new Date()), 3))
	const [payrollCutOff] = useState(subDays(endOfWeek(new Date()), 2))
	const [loading, setLoading] = useState(true)
	const [isConfirmed, setConfirmed] = useState(false)

	const [isSending, setSending] = useState(false)
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const [toast, openToast] = useState(false)
	const [toastLabel, setToastLabel] = useState('')
	const [toastSeverity, setToastSeverity] = useState('success')

	const csvHeaders = [
		{ label: 'EMPLOYEE NAME', key: 'name' },
		{ label: 'ACCOUNT #', key: 'account' },
		{ label: 'AMOUNT', key: 'amount' }
	]

	const [rawSummaryData, setRawSummaryData] = useState([])
	const [bankSummaryData, setBankSummaryData] = useState([])
	const [payrollSummaryData, setPayrollSummaryData] = useState([])

	function printSummary(summary) {
		var printContents = document.getElementById(summary).innerHTML
		document.body.innerHTML = printContents
		document.body.style.background = 'white'

		window.print()
		location.reload()
	}

	return (
		<div>
			<CardWithContent title={'Payroll summary'} subTitle={`For ${format(startDate, 'yyyy MMMM do')} to ${format(endDate, 'MMMM do')}`} actionButtons={renderActionButtons()}>
				<SummaryTable
					renderSummary={renderSummary}
					startDate={startDate}
					endDate={endDate}
					loading={loading}
					setLoading={setLoading}
					setConfirmed={setConfirmed}
					setRawSummaryData={setRawSummaryData}
					setBankSummaryData={setBankSummaryData}
					setPayrollSummaryData={setPayrollSummaryData}
				/>
			</CardWithContent>
			<ScreenOverlay isOpen={isSending} />
			<PrintableBankSummary companyName={companyName} bankSummaryData={bankSummaryData} payrollCutOff={payrollCutOff} />
			<PrintablePayrollSummary companyName={companyName} payrollSummaryData={payrollSummaryData} startDate={startDate} endDate={endDate} />
			<PaySlips rawSummaryData={rawSummaryData} loading={loading} companyName={companyName} startDate={startDate} endDate={endDate} />
			<Toast label={toastLabel} severity={toastSeverity} isOpen={toast} setOpen={openToast} />
			<Modal
				modalIsOpen={modalIsOpen}
				setModalState={setModalIsOpen}
				title="Confirm payroll summary?"
				showCancel={true}
				buttonText="Confirm"
				buttonAction={postCompleteSummary}
				message1={'Are you sure you want to confirm this payroll summary?'}
				message2={'This action cannot be undone'}
			/>
		</div>
	)

	function renderActionButtons() {
		if (isConfirmed) return renderConfirmedButtons()
		else return renderCompleteButton()
	}

	function renderCompleteButton() {
		return (
			<Button className={classes.topButtons} variant="contained" color="secondary" onClick={() => setModalIsOpen(true)} disabled={loading}>
				Confirm payroll
			</Button>
		)
	}

	async function postCompleteSummary() {
		setSending(true)
		const result = await completeSummary(rawSummaryData, startDate, endDate)

		if (result.status == 401 || result.status == 403) {
			history.push(paths.signin)
		} else if (!result.ok) {
			setSending(false)
			setToastLabel('Error confirming summary')
			setToastSeverity('error')
			openToast(true)
		} else {
			setSending(false)
			setToastLabel('Summary confirmed')
			setToastSeverity('success')
			openToast(true)
			setRenderSummary(renderSummary + 1)
		}
	}

	function renderConfirmedButtons() {
		const buttonList = [
			{ name: 'Bank summary', onClick: printSummary, printId: 'printBankSummary' },
			{ name: 'Payroll summary', onClick: printSummary, printId: 'printPayrollSummary' },
			{ name: 'Payslips', onClick: printSummary, printId: 'printPaySlips' }
		]

		return (
			<div>
				{renderCsvButton()}
				<ButtonDropdown className={classes.topButtons} buttonList={buttonList} />
			</div>
		)
	}

	function renderCsvButton() {
		return (
			<CSVLink className={classes.removeDecoration} filename={`bank_summary_${format(startDate, 'yyyy_MMMM_dd')}_to_${format(endDate, 'MMMM_dd')}.csv`} headers={csvHeaders} data={bankSummaryData}>
				<Button className={classes.topButtons} variant="contained" color="secondary" startIcon={<GetAppIcon />} disabled={loading}>
					CSV
				</Button>
			</CSVLink>
		)
	}
}
