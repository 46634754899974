import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import getEmployees from '../../../../adapters/employeeAdapter'
import recordAttendance from '../../../../adapters/attendenceAdapter'

dayjs.extend(utc)
dayjs.extend(timezone)

export async function getEmployeeBarCodes() {
	const result = await getEmployees()

	if (result.status == 401 || result.status == 403) {
		throw new Error('Invalid session')
	} else if (!result.ok) {
		throw new Error(`An error has occured: ${result.status}`)
	} else {
		const resultJSON = await result.json()
		return resultJSON
	}
}

export async function logTime(employeeBarCodeList, employeeCode, logType) {
	if (employeeBarCodeList.includes(employeeCode)) {
		const result = await recordAttendance({ logType: logType, employeeCode: employeeCode })

		if (result.status == 401 || result.status == 403) {
			throw new Error('Invalid session')
		} else if (!result.ok) {
			throw new Error(`An error has occured: ${result.status}`)
		} else {
			const resultJSON = await result.json()
			return resultJSON
		}
	} else {
		throw new Error('Employee does not exist')
	}
}

export function getDefaultLogType() {
	var currentTime = dayjs().tz('Asia/Manila')

	var hours = currentTime.hour()
	var mins = currentTime.minute()

	if (hours >= 14) return 'pmlogout'
	else if ((hours == 12 && mins > 45) || hours == 13) return 'pmlogin'
	else if (hours >= 9) return 'amlogout'
	else return 'amlogin'
}

export function getLogType(logType) {
	if (logType === 'pmlogout') {
		return 'Afternoon out'
	} else if (logType === 'pmlogin') {
		return 'Afternoon in'
	} else if (logType === 'amlogout') {
		return 'Morning out'
	} else if (logType === 'amlogin') {
		return 'Morning in'
	}

	return logType
}

export function getCurrentTime() {
	var time = new Date()

	var hours = time.getHours()
	var minutes = time.getMinutes()
	var ampm = hours >= 12 ? 'pm' : 'am'
	hours = hours % 12
	hours = hours ? hours : 12 // the hour '0' should be '12'
	minutes = minutes < 10 ? '0' + minutes : minutes
	var formattedTime = hours + ':' + minutes + ' ' + ampm

	return formattedTime
}
