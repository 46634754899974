import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

const useStyles = makeStyles(() => ({
	tip: {
		fontSize: '12px'
	}
}))

export default function Hovertip(props) {
	const classes = useStyles()

	if (props.isVisible) {
		return (
			<Tooltip classes={{ tooltip: classes.tip }} title={props.label} placement={props.placement} TransitionComponent={Zoom} arrow>
				{props.children}
			</Tooltip>
		)
	} else return props.children
}

Hovertip.defaultProps = {
	isVisible: true
}

Hovertip.propTypes = {
	children: PropTypes.element.isRequired,
	label: PropTypes.string.isRequired,
	placement: PropTypes.string,
	isVisible: PropTypes.bool
}
