import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import CardWithContent from '../../../.global/cardWithContent'
import EmployeesTable from './employeesTable'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(3)
	},
	header: {
		display: 'flex',
		padding: theme.spacing(3)
	},
	leftAlign: {
		alignItems: 'left'
	},
	rightAlign: {
		marginLeft: 'auto'
	},
	height: {
		height: '41px'
	}
}))

export default function EmployeeContainer() {
	const classes = useStyles()

	return (
		<CardWithContent title={'Employees'}>
			<EmployeesTable />
		</CardWithContent>
	)
}
