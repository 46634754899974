import { useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import format from 'date-fns/format'

import { paths } from '../../../../pages/routes'
import { StyledTable, StyledTableLoading, StyledTableNoData } from '../../../.global/styledTable'
import getEmployees from '../../../../adapters/employeeAdapter'

export default function EmployeesTable() {
	const [rows, setRows] = useState([])
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const columns = [{ name: 'ID' }, { name: 'Full name' }, { name: 'Date of birth' }, { name: 'Hourly rate' }, { name: 'Daily rate' }, { name: 'Phil health' }, { name: 'Pag-IBIG' }, { name: 'SSS' }]

	useEffect(() => {
		setLoading(true)

		getEmployees()
			.then((response) => {
				if (response.status == 200) {
					response.json().then((employeeData) => {
						const rowData = employeeData.map((employee) => ({
							id: employee.employeeNumber,
							fullName: employee.fullName,
							dateOfBirth: format(new Date(employee.dateOfBirth), 'yyyy-MM-dd'),
							hourlyRate: employee.hourlyRate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							dailyRate: employee.dailyRate.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							philhealth: employee.philhealth.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							pagibig: employee.pagibig.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ','),
							sss: employee.sss.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
						}))

						setRows(rowData)
						setLoading(false)
					})
				} else if (response.status == 401 || response.status == 403) {
					history.push(paths.signin)
				}
			})
			.catch(() => {
				setRows([])
			})
	}, [])

	if (rows.length === 0 && !loading) {
		return <StyledTableNoData columns={columns} />
	}

	if (loading) {
		return <StyledTableLoading columns={columns} />
	}

	return <StyledTable columns={columns} rows={rows} />
}
