import getCookie from './getCookie'

export function getHeaders() {
	var myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
	myHeaders.append('Accept', 'application/json')
	myHeaders.append('Authorization', getAuthHeader())

	return myHeaders
}

function getAuthHeader() {
	return 'Bearer ' + getCookie('token')
}
