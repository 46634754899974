import { format, isPast, isFuture, isSameDay } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import clsx from 'clsx'
import DateFnsUtils from '@date-io/date-fns'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'
import React from 'react'

const useStyles = makeStyles((theme) => ({
	day: {
		width: 36,
		height: 36,
		fontSize: theme.typography.caption.fontSize,
		fontFamily: theme.typography.fontFamily,
		fontWeight: 550,
		margin: '0 2px',
		color: 'black'
	},
	highlight: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		borderRadius: '50%',
		'&:hover': {
			background: theme.palette.primary.dark,
			color: theme.palette.common.white,
			borderRadius: '50%'
		}
	},
	dayHidden: {
		color: theme.palette.grey[300],
		fontWeight: 200
	},
	dayNotInMonth: {
		color: 'white'
	}
}))

export default function DateSelect(props) {
	const classes = useStyles()

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<DatePicker
				className={props.optionalClass}
				variant="inline"
				inputVariant="outlined"
				format="do MMMM yyyy"
				label={props.label}
				value={props.value}
				onChange={props.onChange}
				autoOk
				disablePast={props.disablePast}
				disableFuture={props.disableFuture}
				shouldDisableDate={(date) => {
					props.disableDateLogic ? props.disableDateLogic(date) : null
				}}
				renderDay={(date, selectedDate, dayInCurrentMonth) => {
					const day = clsx(classes.day, {
						[classes.highlight]: isSameDay(date, selectedDate),
						[classes.dayHidden]: (props.disableFuture ? isFuture(date) : null) || (props.disablePast ? isPast(date) : null),
						[classes.dayNotInMonth]: !dayInCurrentMonth
					})

					return (
						<IconButton className={day}>
							<span> {format(date, 'd')} </span>
						</IconButton>
					)
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}

DateSelect.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
	disablePast: PropTypes.bool,
	disableFuture: PropTypes.bool,
	disableDateLogic: PropTypes.func,
	optionalClass: PropTypes.string
}
