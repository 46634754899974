import { getHeaders } from './adapterHelper'

export function getLoans() {
	var myHeaders = getHeaders()

	var request = {
		method: 'GET',
		headers: myHeaders
	}

	return fetch('/api/loans', request)
}

export function createLoan(data) {
	var myHeaders = getHeaders()

	var myBody = new URLSearchParams()
	myBody.append('employeeCode', data.employeeCode)
	myBody.append('type', data.type.toLowerCase())
	myBody.append('totalLoan', parseFloat(data.totalLoan))
	myBody.append('dueDate', data.dueDate)
	myBody.append('payInFullOnCurrentPayPeriod', data.payInFullOnCurrentPayPeriod)
	myBody.append('incrementalPaymentAmount', parseFloat(data.incrementalPaymentAmount))

	var request = {
		method: 'POST',
		headers: myHeaders,
		body: myBody
	}

	return fetch('/api/loans', request)
}
