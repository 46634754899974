import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import AccountCircle from '@material-ui/icons/AccountCircle'
import AppBar from '@material-ui/core/AppBar'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import React from 'react'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import { paths } from '../../pages/routes'
import getCookie from '../../adapters/getCookie'

const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	title: {
		flexGrow: 1,
		textAlign: 'center',
		marginLeft: theme.spacing(14),
		fontFamily: 'Alata'
	},
	menuDropdown: {
		width: '200px'
	},
	loggedIn: {
		paddingRight: theme.spacing(1)
	}
}))

export default function TitleBar(props) {
	const classes = useStyles()
	const history = useHistory()

	const [anchorEl, setAnchorEl] = React.useState(null)
	const userIconDropdownIsOpen = Boolean(anchorEl)

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleLogout = () => {
		document.cookie = 'token=; max-age: -1; path=/;'
		history.push(paths.signin)
	}

	return (
		<AppBar position="fixed" className={classes.appBar}>
			<Toolbar>
				<IconButton
					color="inherit"
					onClick={() => {
						props.setDrawerOpen(!props.drawerIsOpen)
					}}
					edge="start">
					{props.drawerIsOpen === false ? <MenuIcon /> : <ChevronLeftIcon />}
				</IconButton>
				<Typography variant="h5" className={classes.title}>
					sweldo
				</Typography>
				<div>
					<IconButton onClick={handleMenu} color="inherit">
						<Typography className={classes.loggedIn} variant="body1">
							{`${getCookie('site')} \\ ${getCookie('username')}`}
						</Typography>
						<AccountCircle />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={anchorEl}
						keepMounted
						transformOrigin={{ vertical: 'top', horizontal: 'right' }}
						open={userIconDropdownIsOpen}
						onClose={() => {
							setAnchorEl(null)
						}}>
						<MenuItem className={classes.menuDropdown} onClick={handleLogout}>
							Logout
						</MenuItem>
					</Menu>
				</div>
			</Toolbar>
		</AppBar>
	)
}

TitleBar.propTypes = {
	drawerIsOpen: PropTypes.bool.isRequired,
	setDrawerOpen: PropTypes.func.isRequired
}
