import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import format from 'date-fns/format'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
	'@media print': {
		printRows: {
			pageBreakInside: 'avoid',
			display: 'flex'
		}
	},
	payslip: {
		minWidth: '600px'
	},
	container: {
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3),
		fontSize: '16px'
	},
	heading: {
		textAlign: 'center'
	},
	boldText: {
		fontWeight: '600'
	},
	slightlyLessBoltText: {
		fontWeight: '550'
	},
	alignRight: {
		textAlign: 'right'
	},
	alignLeft: {
		textAlign: 'left'
	},
	number: {
		fontFamily: 'monospace',
		textAlign: 'right'
	},
	smallerText: {
		fontSize: '14px'
	}
}))

export default function PaySlips(props) {
	const classes = useStyles()
	const [paySlipsPrintable, setPaySlipsPrintable] = useState([])

	useEffect(() => {
		if (!props.loading) {
			let paySlips = []

			props.rawSummaryData.map((employee, index) => {
				paySlips.push(
					<PaySlip
						key={index}
						companyName={props.companyName}
						payrollStart={props.startDate}
						payrollEnd={props.endDate}
						employeeId={employee.id}
						employeeName={employee.fullName}
						employeeBankAccount={employee.bankAccount}
						hoursWorked={employee.totalHoursWorked}
						hourlyRate={employee.hourlyRate}
						holidays={employee.holidayDetails}
						totalEarnings={employee.basePay + employee.totalHolidayPay}
						loans={employee.loanPaymentDetails}
						contributions={employee.contribution}
						totalDeductions={employee.totalLoansPayable + employee.contribution}
						netReceivable={employee.netReceivable}
					/>
				)
			})

			let paySlipPrintGroups = []

			for (let i = 0; i < paySlips.length; i += 2) {
				paySlipPrintGroups.push(
					<div key={i} className={classes.printRows}>
						{paySlips[i]}
						{paySlips[i + 1]}
					</div>
				)
			}

			setPaySlipsPrintable(paySlipPrintGroups)
		}
		console.log(paySlipsPrintable)
	}, [props.loading])

	return (
		<Box id="printPaySlips" display="none" displayPrint="block">
			{paySlipsPrintable}
		</Box>
	)
}

PaySlips.propTypes = {
	rawSummaryData: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	companyName: PropTypes.string.isRequired,
	startDate: PropTypes.object.isRequired,
	endDate: PropTypes.object.isRequired
}

function PaySlip(props) {
	const classes = useStyles()

	return (
		<div className={classes.payslip}>
			<div className={classes.container}>
				{renderTitle()}
				<Divider />
				<Divider />
				<Divider />
				{renderEmployee()}
				<Divider />
				<Divider />
				<Divider />
				{renderEarnings()}
				<Divider />
				{renderDeductions()}
				<Divider />
				<Divider />
				<Divider />
				{renderReceivable()}
				<Divider />
				<Divider />
				<Divider />
				{renderContributionsFootnote()}
			</div>
		</div>
	)

	function renderTitle() {
		return (
			<div className={classes.heading}>
				<Typography variant="h6">{`${props.companyName} WEEKLY PAYSLIP`}</Typography>
				<Typography variant="subtitle2">{`para sa sweldo puon ${format(props.payrollStart, 'yyyy MMMM dd')} hanggang ${format(props.payrollEnd, 'MMMM dd')}`}</Typography>
			</div>
		)
	}

	function renderEmployee() {
		return (
			<Grid container className={classes.heading}>
				<Grid item xs={2}>
					<Typography className={classes.alignLeft} variant="body1">{`${props.employeeId}`}</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography className={classes.alignLeft} variant="body1">{`${props.employeeName}`}</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography className={classes.alignRight} variant="body1">{`${props.employeeBankAccount}`}</Typography>
				</Grid>
			</Grid>
		)
	}

	function renderEarnings() {
		let holidayNames = []
		let holidayHours = []
		let holidayAmounts = []

		props.holidays.map((holiday, index) => {
			holidayNames.push(
				<Typography key={index} className={classes.smallerText} variant="body1">
					&nbsp;&nbsp;{holiday.holidayName}
				</Typography>
			)
			holidayHours.push(
				<Typography key={index} className={`${classes.number} ${classes.smallerText}`} variant="body1">
					{displayNumber(holiday.hoursWorked)}
				</Typography>
			)
			holidayAmounts.push(
				<Typography key={index} className={`${classes.number} ${classes.smallerText}`} variant="body1">
					{displayNumber(holiday.holidayPay)}
				</Typography>
			)
		})

		return (
			<Grid container>
				<Grid item xs={3}>
					<Typography className={classes.boldText} variant="body1">
						Earnings
					</Typography>
					<Typography className={classes.smallerText} variant="body1">
						&nbsp;&nbsp;Standard
					</Typography>
					{holidayNames}
					<Typography className={classes.slightlyLessBoltText} variant="body1">
						Total earnings
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography className={`${classes.boldText} ${classes.alignRight}`} variant="body1">
						Hours
					</Typography>
					<Typography className={`${classes.number} ${classes.smallerText}`} variant="body1">
						{displayNumber(props.hoursWorked)}
					</Typography>
					{holidayHours}
				</Grid>
				<Grid item xs={3}>
					<Typography className={`${classes.boldText} ${classes.alignRight}`} variant="body1">
						Rate
					</Typography>
					<Typography className={`${classes.number} ${classes.smallerText}`} variant="body1">
						{displayNumber(props.hourlyRate)}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography className={`${classes.boldText} ${classes.alignRight}`} variant="body1">
						Amount
					</Typography>
					<Typography className={`${classes.number} ${classes.smallerText}`} variant="body1">
						{displayNumber(props.hoursWorked * props.hourlyRate)}
					</Typography>
					{holidayAmounts}
					<Typography className={`${classes.number} ${classes.slightlyLessBoltText}`} variant="body1">
						{displayNumber(props.totalEarnings)}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	function renderDeductions() {
		let cashAdvanceAmount
		let hdmfAmount
		let sssAmount

		props.loans.map((loan) => {
			if (loan.loanType == 'cashadvance') cashAdvanceAmount = displayNumber(loan.paidOnPayPeriod)
			else if (loan.loanType == 'hdmf') hdmfAmount = displayNumber(loan.paidOnPayPeriod)
			else if (loan.loanType == 'sss') sssAmount = displayNumber(loan.paidOnPayPeriod)
		})

		return (
			<Grid container>
				<Grid item xs={3}>
					<Typography className={classes.boldText} variant="body1">
						Deductions
					</Typography>
					{cashAdvanceAmount ? (
						<Typography className={classes.smallerText} variant="body1">
							&nbsp;&nbsp;Cash advance
						</Typography>
					) : null}
					{hdmfAmount ? (
						<Typography className={classes.smallerText} variant="body1">
							&nbsp;&nbsp;HDMF
						</Typography>
					) : null}
					{sssAmount ? (
						<Typography className={classes.smallerText} variant="body1">
							&nbsp;&nbsp;SSS
						</Typography>
					) : null}
					<Typography className={classes.smallerText} variant="body1">
						&nbsp;&nbsp;Contributions *
					</Typography>
					<Typography className={classes.slightlyLessBoltText} variant="body1">
						Total deductions
					</Typography>
				</Grid>
				<Grid item xs={3} />
				<Grid item xs={3} />
				<Grid item xs={3}>
					<Typography className={`${classes.boldText} ${classes.alignRight}`} variant="body1">
						&nbsp;
					</Typography>
					{cashAdvanceAmount ? (
						<Typography className={`${classes.number} ${classes.smallerText}`} variant="body1">
							{cashAdvanceAmount}
						</Typography>
					) : null}
					{hdmfAmount ? (
						<Typography className={`${classes.number} ${classes.smallerText}`} variant="body1">
							{hdmfAmount}
						</Typography>
					) : null}
					{sssAmount ? (
						<Typography className={`${classes.number} ${classes.smallerText}`} variant="body1">
							{sssAmount}
						</Typography>
					) : null}
					<Typography className={`${classes.number} ${classes.smallerText}`} variant="body1">
						{displayNumber(props.contributions)}
					</Typography>
					<Typography className={`${classes.number} ${classes.slightlyLessBoltText}`} variant="body1">
						{displayNumber(props.totalDeductions)}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	function renderReceivable() {
		return (
			<Grid container>
				<Grid item xs={3}>
					<Typography className={classes.boldText} variant="body1">
						Total receivable
					</Typography>
				</Grid>
				<Grid item xs={3} />
				<Grid item xs={3} />
				<Grid item xs={3}>
					<Typography className={`${classes.boldText} ${classes.alignRight} ${classes.number}`} variant="body1">
						{displayNumber(props.netReceivable)}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	function renderContributionsFootnote() {
		return (
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="subtitle2">* Bulanan na contribution sa SSS, Pag-IBIG, Philhealth na binanga kada semana</Typography>
				</Grid>
			</Grid>
		)
	}

	function displayNumber(number) {
		return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}
}

PaySlip.propTypes = {
	companyName: PropTypes.string.isRequired,
	payrollStart: PropTypes.object.isRequired,
	payrollEnd: PropTypes.object.isRequired,
	employeeId: PropTypes.string.isRequired,
	employeeName: PropTypes.string.isRequired,
	employeeBankAccount: PropTypes.string.isRequired,
	hoursWorked: PropTypes.number.isRequired,
	hourlyRate: PropTypes.number.isRequired,
	holidays: PropTypes.array,
	totalEarnings: PropTypes.number.isRequired,
	loans: PropTypes.array,
	contributions: PropTypes.number.isRequired,
	totalDeductions: PropTypes.number.isRequired,
	netReceivable: PropTypes.number.isRequired
}
